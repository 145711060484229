import {EventsList as EL} from "@xnpmpackages/xcomponents/dist/utils/GlobalEventBus";

const enum customEvents {
    keyBoardShown = 'keyBoardShown',
    keyBoardHide = 'keyBoardHide',
}

// @ts-ignore
export const EventsList = {...EL, ...customEvents};

