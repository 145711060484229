
export default {
    name: "MenuBlock",
    methods: {

        async pushTest() {

            let response = await this.$xapi.post(
                '/common-actions',
                {
                    action: 'pushToAll',
                },
            );

            console.log(response.data);

        }

    },
}
