
import {GlobalEventBus} from '@xnpmpackages/xcomponents/dist/index'
import {EventsList} from "@/const";

const iOS = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export default {
    name: "IosWrapper",
    expose: ['iOS'],
    data() {
        return {
            keyboardOpened: false,
            isIos: false,
        }
    },
    mounted() {

        if (iOS()) {

            this.isIos = iOS()
            document.documentElement.classList.add('is-ios')

            const isKeyboardOpened = () => {
                return (Math.abs(window.visualViewport.height - window.document.body.clientHeight) > 20)
            }

            const handler = () => {

                const wasHeight = window.visualViewport.height

                setTimeout(async () => {

                    if (isKeyboardOpened()) {

                        document.documentElement.style.setProperty('--ios-wrapper-height', `${window.visualViewport.height}px`);
                        this.keyboardOpened = true
                        document.documentElement.classList.add('ios-wrapper-keyboard-opened')
                        GlobalEventBus.emit(EventsList.keyBoardShown, wasHeight, window.visualViewport.height, Math.abs(wasHeight - window.visualViewport.height))

                    } else {
                        document.documentElement.style.setProperty('--ios-wrapper-height', '100vh');
                        this.keyboardOpened = false
                        document.documentElement.classList.remove('ios-wrapper-keyboard-opened')
                        GlobalEventBus.emit(EventsList.keyBoardHide, wasHeight, window.visualViewport.height, Math.abs(wasHeight - window.visualViewport.height))
                    }


                }, 100)

            }

            window.addEventListener('focusin', handler, {passive: false})
            window.addEventListener('focusout', handler, {passive: false})

            let intervalWasHeight = window.visualViewport.height
            let wasKeyboardOpened = isKeyboardOpened()

            setInterval(() => {

                if (isKeyboardOpened() === wasKeyboardOpened)
                    return

                wasKeyboardOpened = isKeyboardOpened()

                if (isKeyboardOpened()) {

                    document.documentElement.style.setProperty('--ios-wrapper-height', `${window.visualViewport.height}px`);
                    this.keyboardOpened = true
                    document.documentElement.classList.add('ios-wrapper-keyboard-opened')
                    GlobalEventBus.emit(EventsList.keyBoardShown, intervalWasHeight, window.visualViewport.height, Math.abs(intervalWasHeight - window.visualViewport.height))

                } else {

                    document.documentElement.style.setProperty('--ios-wrapper-height', '100vh');
                    this.keyboardOpened = false
                    document.documentElement.classList.remove('ios-wrapper-keyboard-opened')
                    GlobalEventBus.emit(EventsList.keyBoardHide, intervalWasHeight, window.visualViewport.height, Math.abs(intervalWasHeight - window.visualViewport.height))

                }

                intervalWasHeight = window.visualViewport.height


            }, 300)

        }

    },
}
