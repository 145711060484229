import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($data.isIos)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: "iosWrapper",
        class: _normalizeClass([{'ios-wrapper-keyboard-opened': $data.keyboardOpened}, "ios-wrapper"]),
        style: {"display":"flex","flex-direction":"column"}
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2))
    : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
}