
import IosWrapper from "@/components/IosWrapper.vue";
import MenuBlock from "@/components/MenuBlock.vue";
import wss from "@/service/WebSocket";


export default {
    name: "SettingsPage",
    components: {MenuBlock, IosWrapper},
    methods: {

        async testPush() {

            const registration = await navigator.serviceWorker.getRegistration();
            registration.showNotification('Notification without Push API', {body: 'qqq'});

        },

        async clearPushes() {

            const reg = await navigator.serviceWorker.ready
            const notifications = await reg.getNotifications()
            console.log(notifications);

            for (const push of notifications) {
                push.close()
            }


        },

        async testWssRequest() {

            this.$store.commit('addLog', 'Запрос...')

            try {
                const response = await wss.request('test', 'some data', 3000)
                this.$store.commit('addLog', {'Ответ': response})
            } catch (e) {
                this.$store.commit('addLog', 'Ошибка запроса')
            }
        }

    }
}
